<template >
  <div class="table-wrap">
    <div class="table-container">
      <div class="table-toolbar">
        <div class="title">
          <div class="text">
            <span>企业信息</span>
            <!-- <span class="desc">(一级)</span> -->
          </div>
        </div>
        <!-- <div class="filters">
          <a-input class="filter" placeholder="企业名称" v-model:value="filter.qymc"></a-input>
          <a-button class="filter-btn" type="primary" @click="handleSearch">搜索</a-button>
        </div> -->
        <div class="opts" v-if="!dataSource.length">
          <a-button class="opt-btn" size="large" type="link" @click="toCreate">新建</a-button>
        </div>
      </div>
      <div class="table">
        <a-table size="middle" class="tableRef" :columns="columns" :row-key="record => record.id"
          :data-source="dataSource" :pagination="pagination" :loading="loading" @change="handleTableChange">
          <template #bodyCell="{ column, record }">
            <template v-if="column.dataIndex == 'sort_num'">
              <div class="drapHandle" style="cursor: pointer;">
                <holder-outlined />
                <span style="margin-left:6px;">{{ record.sort_num }}</span>
              </div>
            </template>
            <template v-else-if="column.dataIndex == 'ssqy'">
              <div>
                <span>{{ record.sheng }}/{{ record.shi }}/{{ record.qu }}</span>
              </div>
            </template>
            <template v-else-if="column.dataIndex === 'operation'">
              <div class="operations">
                <a class="btn" @click="edit(record)">完善信息</a>
              </div>
            </template>
          </template>
        </a-table>
      </div>
    </div>
    <Edit ref="editRef" :current="current" @close="current = null;" @refreshList="handleSearch" />
  </div>
</template>
<script>
import { usePagination } from 'vue-request';
import { computed, defineComponent } from 'vue';
import service from '@/service/service';
// import { message, Modal } from 'ant-design-vue';

import { HolderOutlined } from '@ant-design/icons-vue';
import Edit from './Edit.vue';

export default defineComponent({
  components: {
    HolderOutlined,
    Edit,
    //onMounted
  },

  data() {
    return {
      currentTab: '',
      filter: {
        ...this.initFilter,
        qymc: '',
      },
      current: null,
      rows: null,
      addcompany:0,
    }
  },
  watch: {
  },
  mounted() {
      //console.log(this.$route.query.addcompany); // 'Hello, Vue!'
      this.addcompany=this.$route.query.addcompany;
      if(this.addcompany){
        this.toCreate();
      }

    },
  methods: {
    toCreate() {
      this.current = { pid: '0' }
      this.$refs['editRef'].onShow();
    },
    edit(item) {
      this.current = item;
      this.$refs['editRef'].onShow();
    },
    // 切换页码调用获取
    handleTableChange(pag, filter, sorter) {
      this.run({
        limit: pag.pageSize,
        page: pag?.current,
        sortField: sorter.field,
        sortOrder: sorter.order ? (sorter.order.includes('asc') ? 'asc' : 'desc') : '',
        ...this.filter,
      });
    },
    // 筛选
    handleSearch() {
      this.run({
        page: this.pagination.current,
        limit: this.pagination.pageSize,
        ...this.filter
      })
    },
  },
  
  setup() {
    // 声明并调用一次数据拉取
    let initFilter = {
    }
    const {
      data: dataSource,
      run,
      loading,
      current,
      pageSize,
      total,
    } = usePagination((params) => service.get_company_lists({ ...initFilter, ...params }), {
      formatResult: res => {
        res.data.total_num = res.total_number;
        return res.data;
      },
      pagination: {
        currentKey: 'page',
        pageSizeKey: 'limit',
        totalKey: 'total_num',
      },
    });
    // 分页所需的属性
    const pagination = computed(() => ({
      total: total.value,
      current: current.value,
      pageSize: pageSize.value,
    }));

    // data用
    return {
      dataSource,
      pagination,
      loading,
      initFilter,
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'sort_num',
        // },
        {
          title: '企业名称',
          dataIndex: 'qymc',
        },
        {
          title: '所属区域',
          dataIndex: 'ssqy',
        },
        {
          title: '操作',
          dataIndex: 'operation'
        }
      ],
      run,
    };
  },
})
</script>
<style lang="less" scoped>
.table-wrap {
  margin: 24px 0;
  width: 1400px;
}

.table-container {

  .tableHeadFilter {
    .row {
      border-bottom: 1px solid #f5f6f7;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;

      .label {
        flex: 0 0 auto;
        margin-right: 12px;
        font-size: 18px;
        color: #000;
      }

      .filters {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        .filter {
          flex: 0 0 auto;
          margin: 0 12px;
          font-size: 18px;
          height: 44px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #666;
          padding: 0 12px;
          border-radius: 10px;
          cursor: pointer;

          &.active {
            background: @primary-color;
            color: #fff;
          }
        }
      }
    }

    .search {
      padding-top: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      .filter {
        min-width: 120px;
        margin-right: 12px;
      }

      .filter-btn {
        margin-right: 12px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
</style>