<template>
  <div>
    <a-drawer :title="`${form.id ? '编辑' : '新建'}企业`" :width="1440" :maskClosable="false" :visible="visible"
      :body-style="{ paddingBottom: '80px' }" :footer-style="{ textAlign: 'right' }" @close="onClose">
      <a-descriptions :title="form.title">
        <a-descriptions-item>{{ form.release_time }}</a-descriptions-item>
        <a-descriptions-item>{{ form.area_code }}</a-descriptions-item>
      </a-descriptions>
      <a-form :model="form" :rules="rules" layout="vertical">
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="企业名称" name="qymc">
              <a-input v-model:value="form.qymc" placeholder="请输入" />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="企业简称" name="qyjc">
              <a-input v-model:value="form.qyjc" placeholder="请输入" />
            </a-form-item>
          </a-col>

          <a-col :span="12">

            <a-form-item label="企业类型">
              <a-select :options="ctypes" v-model:value="form.ctype" mode="multiple" placeholder="请选择">
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="所属区域">
              <a-cascader placeholder="请选择" :options="areas" v-model:value="ssqy"></a-cascader>
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="产业类别" name="trade">
              <a-tree-select v-model:value="form.trade" show-search style="width: 100%" :defaultValue="form.trade"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择" allow-clear multiple
                :field-names="{
                  children: 'children',
                  label: 'name',
                  value: 'id',
                }" :tree-data="trades" :matchKeys="label">
              </a-tree-select>
            </a-form-item>
          </a-col>


        </a-row>

        <a-form-item :label="fl.name" class="selectFormItem" v-for="(fl, idx) in tagData" :key="idx">
          <a-row :gutter="16">
            <a-col :span="tag.type == '1' ? 6 : 12" v-for="(tag, tag_idx) in fl.tag_data" :key="tag_idx">
              <div class="checkbox-wrap">
                <a-checkbox v-if="tag.type == '1'" v-model:checked="tag.selected">{{ tag.name }}</a-checkbox>
                <template v-else-if="tag.type == '2'">
                  <a-checkbox v-model:checked="tag.selected">{{ tag.name }}</a-checkbox>
                  <a-input @change="handleInput(tag, $event)" style="width:120px;" v-model:value="tag.value"
                    :suffix="tag.unit" />
                </template>
              </div>
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item class="selectFormItem" label="财务指标" v-if="form.id">
          <a-descriptions bordered :column="1" class="customDescript">
            <a-descriptions-item label="项目类别">
              <a-row :gutter="16">
                <a-col :span="8" v-for="item in financial_year" :key="item.name" >{{ item.name }}年度</a-col>
              </a-row>
            </a-descriptions-item>
            <a-descriptions-item v-for="(row, row_idx) in financials" :key="row.financial_type_id" :label="row.name">
              <a-row :gutter="16">
                <a-col :span="8" v-for="item in financial_year" :key="item.name">
                  <a-input style="border:none;" v-model:value="financials[row_idx][item.name]"
                    placeholder="请输入" suffix="万元"></a-input>
                </a-col>
              </a-row>
            </a-descriptions-item>

          </a-descriptions>
        </a-form-item>

      </a-form>
      <template #extra>
        <a-space>
          <a-button @click="onClose">取消</a-button>
          <a-button :loading="createLoading" type="primary" @click="onConfirm">提交</a-button>
        </a-space>
      </template>
    </a-drawer>
  </div>
</template>
<script>
// import UploadFile from '@/components/common/uploadFile.vue';
import service from '@/service/service';
import { message } from 'ant-design-vue';
import options from '@/util/address-options';
const rules = {};

const formProp = {
  id: '',
  qymc: '',
  qyjc: '',
  trade: undefined,
  ctype:undefined,
  hy: [],
  sheng_code: '',//省code
  sheng: '',//省名称
  shi_code: '',//市code
  shi: '',//市名称
  qu_code: '',//区code
  qu: '',//区名称
}
export default {
  components: {
    // UploadFile
  },
  props: ['current'],
  data() {
    return {
      rules,
      form: {
        ...formProp
      },
      hyTreeData: [],
      ssqy: '',
      tagData: [],
      trades: [],
      ctypes:[],
      //defaultExpandedKeys:[],
      treeExpandedKeys:[],
      visible: false,
      createLoading: false,
      areas: options,
      financial_year: [],
      financials: [],
    }
  },
  watch: {
    current: {
      // 数据有变化的时候, 处理当前窗口的内容
      handler(nowCurrent) {
        if (nowCurrent) {
          let temp = {};
          // 遍历form对象
          for (let key in formProp) {
            let tempVal = (['item','ctype', 'zclx'].includes(key) ? (nowCurrent[key] || undefined) : (nowCurrent[key] || ''));
            temp[key] = tempVal;
            if (key == 'hy') {
              temp[key] = nowCurrent[key] ? nowCurrent[key].split(',').map(m => Number(m)) : [];
            }
            if (key == 'trade') {
              temp[key] = nowCurrent[key] ? nowCurrent[key].split(',').map(m => Number(m)) : [];
            }
            if (key == 'ctype') {
              //console.log(nowCurrent[key]);
              temp[key] = nowCurrent[key] ? nowCurrent[key].split(',').map(m => String(m)) : [];
            }
          }
          if (nowCurrent.sheng_code) {
            // 地区
            let area_temp = [
              nowCurrent?.sheng_code ? (nowCurrent.sheng_code + '/' + nowCurrent.sheng) : '',
              nowCurrent?.shi_code ? (nowCurrent.shi_code + '/' + nowCurrent.shi) : '',
              nowCurrent?.qu_code ? (nowCurrent.qu_code + '/' + nowCurrent.qu) : '',
            ];
            this.ssqy = area_temp.splice(0, nowCurrent.level ? (nowCurrent.level - 1) : 3)
          }
          this.form = temp;

        }
      },
      immediate: true
    }
  },
  methods: {
    handleInput(tag, e) {
      if (e.target.value) {
        tag.selected = true;
      }
      else {
        tag.selected = false;
      }
    },
    async getOptions() {
      let hyRes = await service.get_industry_lists();
      this.hyTreeData = hyRes?.data || [];

      let trades = await service.get_trade_lists();
      this.trades = trades?.data || [];

      let ctypes = await service.get_ctype_lists();
      this.ctypes = ctypes?.data.map(m => ({ value: m.id + '', label: m.name })) || [];

      //this.defaultExpandedKeys = [] // 默认展开全部节点

      let tagRes = await service.get_company_tag({
        id: this.id || this.form.id
      });
      tagRes.data = tagRes.data.map(m => ({
        ...m,
        tag_data: m.tag_data.map(m => ({
          ...m,
          value: m.value || '',
          selected: m.selected || false,
        }))
      }));
      this.tagData = tagRes.data;

      if (this.form.id) {
        // 获取财务表格
        let financial_year = await service.get_setting_info({ key: 'financial_year' });
        let financials = await service.get_company_financial({ id: this.form.id });
        this.financial_year = financial_year.data?.setting_option || [];
        this.financials = financials.data;
      }

    },
    onClose() {
      this.visible = false;
      this.onReset();
      this.$emit('close');
    },
    onReset() {
      let temp = {};
      for (let key in this.form) {
        temp[key] = '';
      }
      this.form = temp;
    },
    onShow() {
      this.visible = true;
      this.getOptions();
    },
    async onConfirm() {

      this.createLoading = true;

      if (this.form.id) {
        if (this.financials) {
          let fi_s = [];
          this.financials.forEach(m => {
            fi_s = fi_s.concat(this.financial_year.map(year => ({
              financial_type_id: m.financial_type_id,
              name: year.name,
              value: m[year.name]
            })));
          });
          await service.set_company_financial({
            id: this.form.id,
            data: fi_s
          });
        }
      }

      const api = this.form.id ? 'edit_company' : 'add_company';
      let tag_data = [];
      this.tagData.forEach(f => {
        f.tag_data.filter(filter => filter.selected).forEach(tag => {
          tag_data.push({
            tag_id: tag.id,
            value: tag.value
          })
        })
      });
      const { code, msg } = await service[api]({
        ...this.form,
        sheng_code: this.ssqy?.[0]?.split('/')[0] || '',
        sheng: this.ssqy?.[0]?.split('/')[1] || '',
        shi_code: this.ssqy?.[1]?.split('/')[0] || '',
        shi: this.ssqy?.[1]?.split('/')[1] || '',
        qu_code: this.ssqy?.[2]?.split('/')[0] || '',
        qu: this.ssqy?.[2]?.split('/')[1] || '',

        tag_data,
        trade: this.form.trade?.join(',') || '',
        hy: this.form.hy?.join(',') || '',
      })

      

      this.createLoading = false;
      if (code && code == '1') {
        message.success(msg || '成功');
        this.$emit('refreshList');
        this.onClose();
      }
    }
  }
}
</script>
<style lang="less">
.selectFormItem {
  .ant-form-item-label {
    label {
      font-size: 16px;
      font-weight: bold;
      color: #fff;
      padding: 0 6px;
      background: #3399ff;
      line-height: 32px;
      border-radius: 3px;
    }
  }
}
</style>
<style lang="less" scoped>
.checkbox-wrap {
  max-height: 32px;
  display: flex;
  align-items: center;
  height: 100%;
  margin-bottom: 12px;
}
</style>